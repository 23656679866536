import React, { useEffect } from "react";
import { Table, Row, Col, Button, Alert, Card } from "react-bootstrap";
import DataTable, { pagination } from "react-data-table-component";
import _ from "underscore";
import axios from "axios";
const dateFormat = require("dateformat");
const { customStyles } = require("./CMS/tableStyle");

const BookmarkedComponent = ({
  currentPage,
  currentPerpage,
  currentSort,
  currentTotal,
  data,
  error,
  errorshow,
  loading,
  setData,
  setErrors,
  setTotal,
  setLoading,
  setLoggedin,
  handlePerRowsChange,
  handlePageChange,
  handleSearch,
}) => {
  //console.log(publicationdata);
  async function fetchSearchHistory() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/getStarred"
          : "/User/getStarred",
        {
          params: {
            page: currentPage,
            sort: currentSort,
            rows: currentPerpage,
          },
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setTotal(res.data.total);
          setLoading(false);
          setLoggedin(1);
        } else {
          const error = new Error(res.message);
          setErrors(res.message);
          // setLoggedin(0);
          setLoggedin(1);
        }
      })
      .catch((error) => {
        if (!_.isEmpty(error)) {
          // setErrors(error);
          // setLoggedin(0);
          setLoggedin(1);
        }
      });
  }
  const columns = [
    {
      name: "Date",
      selector: "createdAt",
      sortable: true,
      wrap: true,
      cell: (row) => row.createdAt,
      // dateFormat(row.createdAt, "dd/mm/yyyy H:M:s"),
    },
    {
      name: "Bookmarked",
      selector: "document_id",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div>
          <a
            href="!/#"
            onClick={(e) => {
              handleSearch(e, row.document_id);
            }}
          >
            {row.title}
          </a>
        </div>
      ),
    },

    // {
    //   name: "Articles Viewed",
    //   selector: "documents_viewed",
    //   sortable: false,
    //   cell: (row) => (
    //     <div>
    //       <div>
    //         {row.documents_viewed ? JSON.parse(row.documents_viewed).length : 0}
    //       </div>
    //     </div>
    //   ),
    // },
  ];
  useEffect(() => {
    document.body.classList.remove("homepage");
    fetchSearchHistory();
  }, [currentPage, currentPerpage, currentSort]);

  return (
    <DataTable
      title="Bookmarked Articles"
      striped={true}
      customStyles={customStyles}
      columns={columns}
      data={data}
      progressPending={loading}
      // expandableRows
      highlightOnHover
      // defaultSortField="name"
      // expandOnRowClicked
      // expandableRowsComponent={<HistoryExpandedComponent />}
      // onSort={handleSort}
      // sortServer
      pagination
      paginationServer
      paginationTotalRows={currentTotal}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      // subHeader
      // subHeaderComponent={subHeaderComponentMemo}
    />
  );
};

export default BookmarkedComponent;
