exports.customStyles = {
  rows: {
    style: {
      minHeight: "25px", // override the row height
      "font-family": "sans-serif",
      "font-size": "14px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "2px", // override the cell padding for head cells
      paddingRight: "2px",
      "font-family": "sans-serif",
      "font-size": "15px",
      "font-weight": "700",
    },
  },
  cells: {
    style: {
      paddingLeft: "3px", // override the cell padding for data cells
      paddingRight: "3px",
    },
  },
};
