import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import { fectchInstitutionAuth } from "./App/context/authInstitution";
// import { userBanner } from "./App/context/userBanner";

import Home from "./App/pages/Home";
import Profile from "./App/pages/Profile";
import History from "./App/pages/History";
import Inbox from "./App/pages/Inbox";
import Bookmarked from "./App/pages/Bookmarked";

import Changepassword from "./App/pages/Changepassword";
import Subscription from "./App/pages/Subscription";
import Checkoutsuccess from "./App/pages/Checkoutsuccess";
import Checkoutcancel from "./App/pages/Checkoutcancel";

import Upgrade from "./App/pages/Upgrade";
import Login from "./App/pages/Login";
import OA from "./App/pages/OA";
import OALogin from "./App/pages/OALogin";
import OARedirect from "./App/pages/OARedirect";

import Logout from "./App/pages/Logout";
import Passwordreset from "./App/pages/Passwordreset";
import UpdateToken from "./App/pages/UpdateToken";

import Registration from "./App/pages/Registration";
import Footer from "./App/components/Footer";
import Contact from "./App/pages/Contact";
import About from "./App/pages/About";
import Popular from "./App/pages/Popular";
import Popularsearches from "./App/pages/Popularsearch";
import Howtouse from "./App/pages/Howtouse";
import Addtrip from "./App/pages/Addtrip";
import Terms from "./App/pages/Terms";
import Key from "./App/pages/Key";
import InstitutionUserAuth from "./App/pages/InstitutionUserAuth";
import Acceptableuse from "./App/pages/Acceptableuse";
import Cookies from "./App/pages/Cookies";
import Privacy from "./App/pages/Privacy";
import Latest from "./App/pages/Latest";
import Top from "./App/pages/Top";
import Pro from "./App/pages/Pro";
import Why from "./App/pages/Why";
import Document from "./App/pages/Document";
import DocumentRedirect from "./App/pages/DocumentRedirect";
import Unsubscribe from "./App/pages/Unsubscribe";
import Searchresult from "./App/pages/Searchresult";
// import TripAnswersAll from "./App/pages/TripAnswersAll";
import TripAnswers from "./App/pages/TripAnswers";
import NoMatch from "./App/pages/NoMatch";
import SearchAPI from "./App/pages/api/SearchApi";

//import Layout from "./App/components/Layout";
import NavigationBarAdmin from "./App/components/NavigationBarAdmin";
import NavigationBar from "./App/components/NavigationBar";
import NavigationBarMobile from "./App/components/NavigationBarMobile";
import NavAdvert from "./App/components/NavAdvert";
import CookieBar from "./App/components/CookieBar";

import CMS from "./App/pages/CMS/CMS";
import SEO from "./App/pages/CMS/SEO/SEO";

import Access from "./App/pages/CMS/Access";
import Publications from "./App/pages/CMS/Publications";
import Settings from "./App/pages/CMS/Settings";
import Users from "./App/pages/CMS/Users";
import Emails from "./App/pages/CMS/Emails";
import LoginAdmin from "./App/pages/CMS/LoginAdmin";
import LoginSEO from "./App/pages/CMS/SEO/LoginSEO";

import { AuthContext } from "./App/context/auth";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import PrivateRouteSEO from "./PrivateRouteSEO";

import PrivateRoute from "./PrivateRoute";
import { checkTokens, checkTokensStorage } from "./App/context/checkTokens";
import {
  handleLoginRemember,
  checkAuth,
  checkExpiration,
  logout,
} from "./App/context/rememberMe";

import _ from "underscore";
import { useIdleTimer } from "react-idle-timer";

const store = require("store");

const Routers = (props) => {
  //Check local storage time
  const timeout = 60000 * 30; ///ms * min
  //var usertimedout = false;
  //Check activity and idle time to destory auth storage.
  const [isIdle, setIsIdle] = useState(false);
  const [isPro, setisPro] = useState(false);
  const [isLoggedin, setisLoggedin] = useState(false);

  const handleOnActive = () => {
    setIsIdle(false);
    if (getRemainingTime() <= 0) {
      store.set("tokens", null);
      store.set("dismiss_tokens", null);
      store.set("cookies_tokens", null);
      store.set("instution_auth_tokens", { institution_auth: null });
    }
  };
  const handleOnIdle = () => setIsIdle(true);
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });
  // End activity and idle time check

  var existingTokens = null,
    existingInstTokens = null;

  if (!_.isUndefined(store.get("tokens"))) {
    existingTokens =
      !_.isNull(store.get("tokens")) && store.get("tokens") !== ""
        ? JSON.parse(store.get("tokens"))
        : null;
  }
  existingInstTokens = !_.isUndefined(store.get("instution_auth_tokens"))
    ? store.get("instution_auth_tokens")
    : "";

  //Remove local storage after a specific time
  const [authTokens, setAuthTokens] = useState(existingTokens);

  /**
   * check if already logged in
   */
  const checkLogin = async () => {
    var getAuth = await checkAuth(setTokens, setisLoggedin);
    var loginObj = checkTokensStorage();

    if (loginObj.chkLogin == false || getAuth == false) {
      await handleLoginRemember(setTokens);
    }
    await fetchLoginTokens();
  };

  /**
   * fetch login tokens
   */
  const fetchLoginTokens = async () => {
    if (existingTokens && !isPro) {
      setisPro(
        authTokens.isPro > 0 ||
          authTokens.institution_pro > 0 ||
          !_.isNull(existingInstTokens.institution_auth) ||
          isPro
      );
    }
  };
  /**
   * check institution login
   */
  const fetchInstitutionTokens = async () => {
    // if (existingInstTokens && _.isNull(authTokens)) {
    if (existingInstTokens && _.isNull(authTokens)) {
      //Make a call if empty
      if (_.isNull(existingInstTokens.institution_auth)) {
        existingInstTokens = await fectchInstitutionAuth();
      }
      setisPro(!_.isNull(existingInstTokens.institution_auth) || isPro);
    } else {
      existingInstTokens = await fectchInstitutionAuth();
      if (authTokens) {
        if (
          !_.isUndefined(authTokens.isPro) ||
          !_.isUndefined(authTokens.institution_pro)
        )
          setisPro(
            !_.isNull(existingInstTokens.institution_auth) ||
              authTokens.isPro > 0 ||
              authTokens.institution_pro > 0
          );
      } else setisPro(!_.isNull(existingInstTokens.institution_auth));
    }
  };
  let componentMounted = true; // (3) component is mounted

  useEffect(() => {
    if (componentMounted) {
      fetchInstitutionTokens();
      checkLogin();

      //  fetchLoginTokens();
    }
    return () => {
      // This code runs when component is unmounted
      componentMounted = false; // (4) set it to false if we leave the page
    };
  }, [existingTokens]);
  /**
   * setTokens
   * @param {*} data
   */
  const setTokens = (data) => {
    store.set("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };
  return (
    <Switch>
      {/* Admin Layout */}
      <Route path="/SEO">
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
          <div>
            <NavigationBarAdmin></NavigationBarAdmin>
          </div>
          <Switch>
            <Route path="/SEO/Login" exact component={LoginSEO} />

            <PrivateRouteSEO path="/SEO" exact component={SEO} />
          </Switch>
          <Footer />
        </AuthContext.Provider>
      </Route>

      <Route path="/Admin">
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
          <div>
            <NavigationBarAdmin></NavigationBarAdmin>
          </div>
          <Switch>
            <Route path="/Admin/Login" exact component={LoginAdmin} />

            <PrivateRouteAdmin path="/Admin" exact component={CMS} />
            <PrivateRouteSEO path="/Admin/SEO" exact component={SEO} />

            <PrivateRouteAdmin
              path="/Admin/Publications"
              exact
              component={Publications}
            />
            <PrivateRouteAdmin path="/Admin/Access" exact component={Access} />
            <PrivateRouteAdmin
              path="/Admin/Settings"
              exact
              component={Settings}
            />
            <PrivateRouteAdmin path="/Admin/Users" exact component={Users} />
            <PrivateRouteAdmin path="/Admin/Emails" exact component={Emails} />
            <Route component={NoMatch} />
          </Switch>
          <Footer />
        </AuthContext.Provider>
      </Route>

      {/* End Admin Layout */}
      <Route path="/api">
        <Switch>
          <Route path="/api/search" exact component={SearchAPI} />
        </Switch>
      </Route>

      <Route>
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
          <NavigationBarMobile isPro={isPro}></NavigationBarMobile>
          <NavAdvert isPro={isPro}></NavAdvert>
          <NavigationBar isPro={isPro} isLoggedin={isLoggedin}></NavigationBar>
          <Switch>
            <PrivateRoute path="/" exact component={Home} />
            <PrivateRoute path="/Home" component={Home} />
            <Route path="/About" component={About} />
            <Route path="/Howtouse" component={Howtouse} />
            <Route path="/Addtrip" component={Addtrip} />
            <Route path="/Terms" component={Terms} />
            <Route path="/Acceptableuse" component={Acceptableuse} />
            <Route path="/Privacy" component={Privacy} />
            <Route path="/Cookies" component={Cookies} />
            <Route path="/Latest" component={Latest} />
            <Route path="/Key" component={Key} />
            <PrivateRoute path="/Top/:criteria" component={Top} />
            <Route path="/Document/:criteria" component={Document} />
            <PrivateRoute path="/Popular/:criteria" component={Popular} />
            <PrivateRoute path="/Popular" component={Popular} />
            <PrivateRoute path="/Popularsearches" component={Popularsearches} />
            {/* <PrivateRoute path="/q&a/all" component={TripAnswersAll} /> */}
            <Route path="/q&a/:question" component={TripAnswers} />
            <Route path="/r/:criteria" component={DocumentRedirect} />
            <Route path="/account/auth" component={InstitutionUserAuth} />
            <Route path="/Login" component={Login} />
            <Route path="/OA" exact component={OA} />
            <Route path="/oa_redirect" exact component={OARedirect} />
            <Route path="/oa_login" exact component={OALogin} />
            <Route path="/Passwordreset" component={Passwordreset} />
            <Route path="/UpdateToken" component={UpdateToken} />
            <Route path="/Unsubscribe" component={Unsubscribe} />
            <PrivateRoute path="/Profile" component={Profile} />
            <PrivateRoute path="/Changepassword" component={Changepassword} />
            <PrivateRoute path="/History" component={History} />
            <PrivateRoute path="/Inbox" component={Inbox} />
            <PrivateRoute path="/Bookmarked" component={Bookmarked} />
            <PrivateRoute path="/Subscription" component={Subscription} />
            <Route path="/Checkoutsuccess" component={Checkoutsuccess} />
            <Route path="/Checkoutcancel" component={Checkoutcancel} />
            <Route path="/Upgrade" component={Upgrade} />
            {/* <Route Path="/account/upgrade" component={Upgrade} /> */}
            <Route path="/Contact" component={Contact} />
            <Route path="/Logout" component={Logout} />
            <PrivateRoute path="/Pro" component={Pro} />
            <PrivateRoute path="/Why" component={Why} />
            <PrivateRoute path="/Registration" component={Registration} />
            <PrivateRoute path="/Searchresult" component={Searchresult} />
            <PrivateRoute path="/Search" component={Searchresult} />

            <Route component={NoMatch} />
          </Switch>
          <CookieBar />
          <Footer />
          {/* </Layout> */}
        </AuthContext.Provider>
      </Route>
    </Switch>
  );
};

export default Routers;
